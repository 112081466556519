<template>
    <v-dialog
        v-model="show"
        :transition="false"

        fullscreen
    >
        <v-app
            id="inspire"
            class="ventesapp"
        >
            <v-layout
                class="ArticleContainer"
                column
                fill-height
            >
                <header>
                    <div class="gradient" />
                    <v-app-bar
                        color="black"
                        dark
                    >
                        <v-btn
                            icon
                            @click="show = !show"
                        >
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                        <span
                            class="text-h6"
                            v-if="!searchBar"
                        >
                            {{ $t("summary.add") }}
                        </span>
                        <v-text-field
                            v-if="searchBar"
                            :value="searchArticle"
                            @input="onInput"
                            clearable
                            placeholder="Search for Articles"
                            class="searchBar"
                            ref="search"
                        />
                        <v-spacer />
                        <v-btn
                            icon
                            @click="openSearch"
                        >
                            <v-icon>search</v-icon>
                        </v-btn>
                    </v-app-bar>
                </header>
                <addArticleList
                    class="articleList"
                    :articles="articles"
                    :selectedClient="selectedClient"
                    :searchArticle="searchArticle"
                    :showPrices="showPrices"
                    @addArticle="onAddArticle($event)"
                    @closeDialog="close"
                />
            </v-layout>
        </v-app>
    </v-dialog>
</template>

<script>
import addArticleList from '../components/addArticleList'
import _ from 'lodash'

export default {
    name: 'AddArticlesDialog',
    components: {
        addArticleList
    },
    props: {
        articles: {
            type: Array,
            default () {
                return []
            }
        },
        showPrices: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    data () {
        return {
            show: false,
            selectedClient: {},
            searchBar: false,
            searchArticle: ''
        }
    },
    computed: {
        filteredArticles () {
            if (!this.searchArticle) {
                return this.sortedArticles
            } else {
                return this.articles.filter((article) => {
                    return article.fullName.toLowerCase().includes(this.searchArticle.toLowerCase()) ||
                    article.article.toLowerCase().includes(this.searchArticle.toLowerCase()) ||
                    article.fullDesignation.toLowerCase().includes(this.searchArticle.toLowerCase())
                })
            }
        }
    },
    methods: {
        open () {
            this.show = true
        },
        close () {
            this.show = false
            this.searchBar = false
            this.searchArticle = ''
        },
        save () {
            this.$emit('updateComment', {
                comment: this.input
            })
            this.resolve(this.input)
            this.show = false
        },
        onAddArticle ({ item, count }) {
            this.$emit('addArticle', {
                item: item,
                count: count
            })
        },
        openSearch () {
            this.searchBar = true
            this.$nextTick(() => this.$refs.search.focus())
        },
        onInput: _.debounce(function (searchArticle) {
            this.searchArticle = searchArticle
        }, 300)
    }
}
</script>
<style>
.centerLoading {
    display: flex;
    align-items: center;
    justify-content: center;
}
.loadingscreen {
    width: 100;
    height: 100;
}
.ArticleContainer{
display: flex;
flex-direction: column;
flex-wrap: nowrap;
}
.ArticleContainer header {
flex-shrink: 0;
display: flex;
flex-direction: column;
flex-wrap: nowrap;
}
.ArticleContainer .articleList {
overflow: auto;
flex-grow: 1;
}
</style>
