<template>
    <v-dialog
        v-model="show"
        min-width="300"
        max-width="400"
        ref="deleteDialog"
        persistent
    >
        <v-card>
            <v-card-text class="pt-5">
                <h1>
                    {{ $t("deleteDialog.delete") }}
                </h1>
                <h2>
                    {{ $t("deleteDialog.sure") }}
                </h2>
                <div>
                    <v-btn
                        color="green darken-1"
                        dark
                        @click="deleteOrder"
                        width="130"
                    >
                        OK
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        dark
                        @click="show = false"
                        width="130"
                    >
                        {{ $t("deleteDialog.cancel") }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'deleteDialog',
    data () {
        return {
            show: false,
            resolve: null,
            reject: null
        }
    },
    methods: {
        open () {
            this.show = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        deleteOrder () {
            this.$emit('deleteOrder')
            this.show = false
        }
    }
}
</script>
