<template>
    <div class="articleListContainer">
        <v-list
            class="article-list"
        >
            <template v-if="scrollerItems.length === 0">
                <v-card>
                    <v-card-title
                        class="text-h5 grey darken-2 white--text font-weight-bold"
                        primary-title
                    >
                        No Sales
                    </v-card-title>
                </v-card>
            </template>
            <RecycleScroller
                class="scroller"
                :items="scrollerItems"
                key-field="id"
                v-slot="{ item }"
            >
                <template v-if="item.type === 'ArticleListItem'">
                    <addArticleListItem
                        :item="item.article"
                        :searchArticle="searchArticle"
                        :showPrices="showPrices"
                        @addArticle="onAddArticle(item.article, $event)"
                    />
                    <v-divider />
                </template>
                <template v-if="item.type === 'GroupHeader'">
                    <v-card>
                        <v-card-title
                            class="text-h5 grey darken-2 white--text font-weight-bold"
                            primary-title
                        >
                            {{ item.text }}
                        </v-card-title>
                    </v-card>
                </template>
            </RecycleScroller>
        </v-list>
    </div>
</template>

<script>
import addArticleListItem from '../components/addArticleListItem.vue'
import orderBy from 'lodash/orderBy'

export default {
    name: 'articleList',
    components: {
        addArticleListItem
    },
    props: {
        articles: {
            type: Array,
            default () {
                return []
            }
        },
        selectedClient: {
            type: Object,
            default () {
                return {}
            }
        },
        orderID: {
            type: Number,
            default () {
                return null
            }
        },
        searchArticle: {
            type: String,
            default () {
                return ''
            }
        },
        showPrices: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    computed: {
        sortedArticles () {
            return orderBy(this.articles, ['fullDesignation', 'fullName'])
        },
        filteredArticles () {
            if (!this.searchArticle) {
                return this.sortedArticles
            } else {
                return this.sortedArticles.filter((article) => {
                    return article.fullName.toLowerCase().includes(this.searchArticle.toLowerCase()) ||
                    article.article.toLowerCase().includes(this.searchArticle.toLowerCase()) ||
                    article.fullDesignation.toLowerCase().includes(this.searchArticle.toLowerCase())
                })
            }
        },
        scrollerItems () {
            const scrollerItems = []
            let currentHeader = null
            this.filteredArticles.forEach(article => {
                if (currentHeader !== article.fullDesignation) {
                    scrollerItems.push({
                        type: 'GroupHeader',
                        id: 'GroupHeader:' + article.article,
                        text: article.fullDesignation,
                        size: 72
                    })
                    currentHeader = article.fullDesignation
                }
                scrollerItems.push({
                    type: 'ArticleListItem',
                    id: 'ArticleListItem:' + article.article,
                    article,
                    size: 80
                })
            })
            return scrollerItems
        }
    },
    methods: {
        formatPrice (value) {
            const val = value.toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        },
        onAddArticle (item, { count }) {
            this.$emit('addArticle', {
                item: item,
                count: count
            })
            this.closeDialog()
        },
        closeDialog () {
            this.$emit('closeDialog')
        }
    }
}
</script>
<style>
.article-list {
    flex-grow: 1;
    overflow: auto;
    padding: 0;
}
.centered-input input {
    text-align: center;
}
.scroller {
    height: 100%;
}
.articleListContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
}
.recapContainer {
    flex-shrink: 0;
    border-bottom: solid 1px rgba(0,0,0,0.12);
}
</style>
