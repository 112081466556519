<template>
    <v-card
        :key="item.article"
        class="arts"
    >
        <v-container grid-list-md text-center container--fluid>
            <v-layout>
                <v-flex d-flex xs9 class ="textSize14 font-weight-bold">
                    <text-highlight :queries="searchArticle" class="flexGrow">
                        {{order.item.fullName}}
                    </text-highlight>
                    <span v-if="showPrices">- {{order.item.unitPrice}}</span>
                </v-flex>
                <v-flex xs4
                        v-if="item.deliveryDateList"
                >
                    <v-card-actions class ="textSize20 font-weight-bold">
                        {{ allQuantity }}
                        <v-flex xs10 lg7>
                            <v-select
                                v-model="input.item_id"
                                :items="order.item.packages"
                                item-value="id"
                                item-text="dropDownName"
                                :disabled="valid"
                                class="custom"
                                @change="changePackage()"
                                :suffix="suffixArticle"
                            ></v-select>
                        </v-flex>
                    </v-card-actions>
                </v-flex>
                <v-flex v-else>
                    <v-card-actions class ="textSize21 font-weight-bold">
                        <v-flex xs5>
                            <v-text-field
                                :value="order.quantity[0]"
                                class="centered-input custom"
                                :disabled="valid"
                                type="number"
                                @input="onInput"
                                single-line
                            />
                        </v-flex>
                        <v-flex xs10 lg7>
                            <v-select
                                v-model="input.item_id"
                                :items="order.item.packages"
                                :disabled="disableSelect || valid"
                                item-value="id"
                                :style="{ fontSize: '14px'}"
                                item-text="dropDownName"
                                @change="changePackage()"
                                :suffix="suffixArticle"
                                class="custom"
                            ></v-select>
                        </v-flex>
                    </v-card-actions>
                </v-flex>
            </v-layout>
            <component
                v-bind:is="currentListComponent"
                class="tab"
                :singleCount="singleCount"
                :optional="order.optional"
                :valid="valid"
                @openComment="openComment(order.comment)"
                @incrementSingle="incrementSingle"
                @decrementSingle="decrementSingle"
            />

        </v-container>
        <commentDialog
            @updateComment="onUpdateComment($event)"
            ref="comment"
            :valid="valid"
        />
    </v-card>
</template>

<script>
import moment from 'moment'
import debounce from 'lodash/debounce'
import commentDialog from '../components/commentDialog'
import listComponents from '../components/listcomponents/listComponents'

export default {
    name: 'recapListItem',
    components: {
        commentDialog
    },
    props: {
        item: {
            type: Object,
            default () {
                return {}
            }
        },
        order: {
            type: Object,
            default () {
                return {}
            }
        },
        searchArticle: {
            type: String,
            default () {
                return ''
            }
        },
        valid: {
            type: Boolean,
            default () {
                return false
            }
        },
        showPrices: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    data () {
        return {
            focusBox: 0,
            selectedColor: '#05aa00',
            defaultColor: 'grey',
            input: {
                item_id: this.order.package.id
            },
            propOrder: {},
            selectType: this.item.selectType ?? 'Normal'
        }
    },
    computed: {
        currentListComponent: function () {
            return listComponents['list' + this.selectType]
        },
        singleCount () {
            return Number(this.order.quantity[0])
        },
        calculatedPrice () {
            return this.allQuantity * +this.order.item.unitPrice * this.order.package.unitQuantity
        },
        formatDate () {
            return moment(this.order.item.sales.saleDate).format('DD MMMM YYYY')
        },
        allQuantity () {
            let totalQuantity = 0
            this.order.quantity.forEach((quantity) => {
                totalQuantity = +totalQuantity + +quantity
            })
            return totalQuantity
        },
        suffixArticle () {
            if (this.showPrices) {
                return '| ' + (this.calculatedPrice).toFixed(2) + '€'
            }
            return ''
        },
        disableSelect () {
            if (this.order.item.packages) {
                if (this.order.item.packages.length <= 1) {
                    return true
                }
            }
            return false
        }
    },
    created () {
        this.propOrder = this.order
    },
    methods: {
        select (week) {
            this.item.deliveryDateList.forEach((week) => {
                week.selected = false
            })
            week.selected = true
        },
        formatPrice (value) {
            const val = value.toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        },
        increment () {
            this.$emit('updateCount', {
                count: this.order.item.deliveryDateList[this.focusBox].quantity + 1,
                index: this.focusBox
            })
        },
        decrement () {
            this.$emit('updateCount', {
                count: this.order.item.deliveryDateList[this.focusBox].quantity - 1,
                index: this.focusBox
            })
        },
        incrementSingle ({ count, optional }) {
            this.$emit('updateCount:single', {
                count: count,
                optional: optional
            })
        },
        decrementSingle ({ count }) {
            this.$emit('updateCount:single', {
                count: count
            })
        },
        changePackage () {
            this.$emit('updatePackage', {
                packageID: this.input.item_id
            })
        },
        onUpdateComment ({ comment }) {
            this.$emit('updateComment', {
                comment: comment
            })
        },
        openComment (value) {
            const dialog = this.$refs.comment
                .open(
                    value
                )
                .then((value) => {
                    return value
                })
            return dialog
        },
        onInput: debounce(function (quantity) {
            const decimal = Math.floor((quantity * Math.pow(10, this.item.quantityNumber))) / Math.pow(10, this.item.quantityNumber)
            this.propOrder.quantity[0] = decimal
            this.$emit('updateCount:single', {
                count: decimal
            })
        }, 500)
    }
}
</script>
<style >

.centered-input input {
  text-align: center;
  font-size: 20px;
}
.scroller {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
}
.margLine {
    margin-left: 4px;
    margin-top: -12px;
    margin-bottom: 10px;
}
.arts {
  padding-top: 10px;
}
.flexGrow {
    flex-grow: 0 !important;
}
.v-select.v-input--is-dirty input {
  display: none;
}

.v-select__selection {
  max-width: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.textSize14 {
    font-size: 14px;
}
.textSize20 {
    font-size: 20px;
}
.textSize21 {
    font-size: 21px;
}
</style>
