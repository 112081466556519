<template>
    <v-dialog
        v-model="show"
        min-width="250"
        max-width="600"
    >
        <v-card>
            <v-app-bar
                dark
            >
                <v-app-bar-title>
                    {{ $t('commentDialog.comment') }}
                </v-app-bar-title>
                <v-spacer />
                <v-btn
                    icon
                    @click="show = false"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-app-bar>
            <v-textarea
                v-model="input"
                :disabled="valid"
                :label= "$t('commentDialog.comment')"
                @change="save"
                ref="comment"
                filled
            />
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'commentDialog',
    data () {
        return {
            show: false,
            resolve: null,
            reject: null,
            input: null
        }
    },
    props: {
        valid: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    methods: {
        open (input) {
            this.input = input
            this.show = true
            this.$nextTick(() => this.$refs.comment.focus())
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        save () {
            this.$emit('updateComment', {
                comment: this.input
            })
            this.resolve(this.input)
            this.show = false
        }
    }
}
</script>
