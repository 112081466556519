<template>
    <v-dialog
        v-model="show"
        fullscreen
        :transition="false"
        persistent
    >
        <v-layout
            class="signatureContainer"
            column
            fill-height
        >
            <header>
                <div class="gradient" />
                <v-app-bar
                    color="black"
                    dark
                >
                    <v-btn
                        icon
                        @click="close"
                    >
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <span
                        class="text-h6"
                    >
                        {{ $t("signature.signature") }}
                    </span>
                </v-app-bar>
            </header>
            <div class="articleListContainer">
                <v-list class="recapContainer">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class ="textSize20">
                                {{ selectedClient.fullName}}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-subtitle class ="textSize20 font-weight-bold">
                                <span v-if="showPrices">{{ (allTotal).toFixed(2) }} € | </span> {{ allQuantity }} {{ singleOrMultiple }}
                            </v-list-item-subtitle>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-card
                    class="article-list"
                >
                    <v-list>
                        <v-list-item
                            v-for="item in filterOrder"
                            :key="item.article"
                        >
                            <v-list-item-content>
                                {{item.article}} {{articleName(item)}}
                            </v-list-item-content>
                            <v-list-item-action v-if="item.quantity.length > 1">
                                {{calcQuantity(item)}}x {{item.package.dropDownName}} <span v-if="showPrices">| {{calcPrice(item)}} € </span>
                            </v-list-item-action>
                            <v-list-item-action v-else>
                                {{item.quantity[0]}}x {{item.package.dropDownName}} <span v-if="showPrices">| {{calcPrice(item)}} € </span>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
                <v-list
                    class="recapContainer"
                    v-if="paymentsEnabled"
                >
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class ="textSize20">
                                {{ $t("signature.paiement") }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <hr>
                <v-item-group
                    v-if="paymentsEnabled"
                    active-class="grey"
                    v-model="paymentID"
                >
                    <v-container fluid>
                        <v-row
                            dense
                            justify="center"
                        >
                            <v-col
                                v-for="payment in payments"
                                :key="payment.type"
                                cols="auto"
                            >
                                <v-item v-slot="{ active, toggle }">
                                    <v-card
                                        class="d-flex align-center"
                                        height="100"
                                        width="150"
                                        @click="toggle"
                                    >
                                        <v-scroll-y-transition>
                                            <div
                                                v-if="active"
                                                class="flex-grow-1 text-center"
                                            >
                                                {{payment.label}}
                                            </div>
                                            <div
                                                v-else
                                                class="flex-grow-1 text-center"
                                            >
                                                {{payment.label}}
                                            </div>
                                        </v-scroll-y-transition>
                                    </v-card>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-item-group>
                <div>
                    <v-lazy
                        v-model="isActive"
                    >
                        <VueSignaturePad
                            class="signatureField"
                            width="100%"
                            height="200px"
                            ref="signaturePad"
                        />
                    </v-lazy>
                </div>
                <v-footer
                    class="signatureFooter"
                    height="60"
                >
                    <v-spacer />
                    <v-btn
                        dark
                        @click="save"
                    >
                        {{ $t("signature.valid") }}
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        dark
                        @click="clear"
                    >
                        clear
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        :dark="!valide"
                        :disabled="valide"
                        @click="close"
                    >
                        {{ $t("signature.cancel") }}
                    </v-btn>
                    <v-spacer />
                </v-footer>
            </div>
        </v-layout>
    </v-dialog>
</template>

<script>
export default {
    name: 'signatureDialog',
    props: {
        allTotal: {
            type: Number,
            default () {
                return 0
            }
        },
        orders: {
            type: Array,
            default () {
                return []
            }
        },
        allQuantity: {
            type: Number,
            default () {
                return 0
            }
        },
        selectedClient: {
            type: Object,
            default () {
                return {}
            }
        },
        payments: {
            type: Array,
            default () {
                return []
            }
        },
        paymentsEnabled: {
            type: Boolean,
            default () {
                return false
            }
        },
        showPrices: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    data () {
        return {
            show: false,
            resolve: null,
            reject: null,
            valide: false,
            isActive: false,
            paymentID: ''
        }
    },
    computed: {
        singleOrMultiple () {
            if (this.allQuantity > 1) {
                return 'Items'
            }
            return 'Item'
        },
        filterOrder () {
            return this.orders.filter((item) => {
                return item.quantity >= 1 || item.comment
            })
        }
    },
    methods: {
        open () {
            this.show = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        close () {
            this.clear()
            this.paymentID = ''
            this.show = false
        },
        clear () {
            this.$refs.signaturePad.clearSignature()
        },
        articleName (item) {
            let name = ''
            if (item.item) {
                name = ' - ' + item.item.designation || item.item.fullName
            }
            return name
        },
        calcPrice (item) {
            let price = 0
            price = item.item.unitPrice * item.quantity[0]
            const val = price.toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        },
        calcAllPrice (item) {
            let price = 0
            let totalQuantity = 0
            totalQuantity = this.calcQuantity(item)
            price = item.item.unitPrice * totalQuantity
            return price
        },
        calcQuantity (item) {
            let totalQuantity = 0
            item.quantity.forEach((quantity) => {
                totalQuantity = +totalQuantity + +quantity
            })
            return totalQuantity
        },
        save () {
            const { data } = this.$refs.signaturePad.saveSignature()
            this.$emit('saveSignature', {
                signature: data,
                selectedPayment: this.payments[this.paymentID]
            })

            this.show = false
        }
    }
}
</script>
<style>
    .signatureContainer{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .signatureContainer header {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .signatureContainer .orderList {
        overflow: auto;
        flex-grow: 1;
    }
    .articleListContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 100%;
    }
    .recapContainer {
        flex-shrink: 0;
        border-bottom: solid 1px rgba(0,0,0,0.12);
    }
    .signatureFooter {
        flex-shrink: 0;
        height: 60px;
        flex-basis: 60px;
        min-height: 60px;
    }
    .signatureField {
        background-image: linear-gradient(rgb(156, 155, 155));
        background-origin: border-box;
        background-clip: content-box, border-box;
    }
    .textSize20{
        font-size: 20px;
    }
</style>
