<template>
    <v-list-item
        :key="item.article"
        class="addArts"
    >
        <v-container grid-list-md text-center container--fluid>
            <v-layout>
                <v-flex xs1 v-if="item.stockQuantity">
                    <v-list-item-action-text class ="textSize18 font-weight-bold" >
                        {{ parseInt(item.stockQuantity) }}
                    </v-list-item-action-text>
                </v-flex>
                <v-flex d-flex xs6 class ="textSize14 font-weight-bold">
                    <text-highlight :queries="searchArticle">{{item.fullName}}</text-highlight>
                </v-flex>
                <v-flex xs4 >
                    <v-list-item-action-text
                        v-if="showPrices"
                        class ="textSize18 font-weight-bold"
                    >
                        {{ (calculatedPrice).toFixed(2) }} €
                    </v-list-item-action-text>
                </v-flex>
                <v-flex xs2 >
                    <v-btn
                        dark
                        fab
                        small
                        @click="add()"
                    >
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-list-item>
</template>

<script>

export default {
    name: 'articleListItem',
    props: {
        item: {
            type: Object,
            default () {
                return {}
            }
        },
        searchArticle: {
            type: String,
            default () {
                return ''
            }
        },
        showPrices: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    computed: {
        singleCount () {
            return +this.item.quantity
        },
        calculatedPrice () {
            return 1 * +this.item.unitPrice
        }
    },
    methods: {
        formatPrice (value) {
            const val = value.toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        },
        add () {
            this.$emit('addArticle', {
                count: 1
            })
        }
    }
}
</script>
<style >

.centered-input input {
  text-align: center;
}
.scroller {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
}
.margLine {
    margin-left: 4px;
    margin-top: -12px;
    margin-bottom: 10px;
}
.addArts {
  height: 90px;
}

.textSize18 {
    font-size: 18px;
}

.textSize14 {
    font-size: 14px;
}

</style>
