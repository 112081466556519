<template>
    <div class="articleListContainer">
        <v-list class="recapContainer">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class ="textSize20">
                        {{ selectedClient.fullName}}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-list-item-subtitle class ="textSize20 font-weight-bold">
                        <span v-if="showPrices">{{ (allTotal).toFixed(2) }} € | </span>{{ allQuantity }} {{ singleOrMultiple }}
                    </v-list-item-subtitle>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-card
            class="article-list"
        >
            <template v-if="scrollerItems.length === 0">
                <v-card-title
                    class="text-h5 grey darken-2 white--text font-weight-bold"
                    primary-title
                >
                    No Sales
                </v-card-title>
            </template>
            <DynamicScroller
                class="scroller"
                :items="scrollerItems"
                :min-item-size="50"
                key-field="id"
            >
                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem
                        :item="item"
                        :active="active"
                        :data-index="index"
                    >
                        <template v-if="item.type === 'ArticleListItem'">
                            <recapListItem
                                :item="item.item"
                                :order="getOrder(item.item)"
                                :searchArticle="searchArticle"
                                :valid="valid"
                                :showPrices="showPrices"
                                @updateCount="onUpdateCount(getOrder(item.item), $event)"
                                @updateCount:single="onUpdateCountSingle(getOrder(item.item), $event)"
                                @updatePackage="onUpdatePackage(getOrder(item.item), $event)"
                                @updateComment="onUpdateComment(getOrder(item.item), $event)"
                            />
                            <v-divider />
                        </template>
                        <template v-if="item.type === 'GroupHeader'">
                            <v-card-title
                                class="text-h5 grey darken-2 white--text font-weight-bold"
                                primary-title
                            >
                                {{ item.text }}
                            </v-card-title>
                        </template>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller>
        </v-card>
        <v-snackbar
            v-model="notification"
            bottom
        >
            {{ $t("articleList.nothing") }}
            <v-btn
                dark
                text
                @click="notification = false"
            >
                Close
            </v-btn>
        </v-snackbar>
        <signatureDialog
            :selectedClient="selectedClient"
            :allTotal="allTotal"
            :allQuantity="allQuantity"
            :orders="orders"
            :payments="payments"
            :paymentsEnabled="paymentsEnabled"
            :showPrices="showPrices"
            @saveSignature="onSaveSignature($event)"
            ref="signatureDialog"
        />
        <v-footer
            class="articleListFooter"
            height="60"
        >
            <v-spacer />
            <v-btn
                :dark="!signatureValidate"
                :disabled="signatureValidate"
                @click="save()"
            >
                {{ $t("articleList.saveOrder") }}
            </v-btn>
            <v-spacer/>
            <v-btn
                v-if="this.settings.signature"
                :dark="!valid"
                :disabled="valid"
                @click="openSignatureDialog()"
            >
                {{ $t("articleList.sign") }}
            </v-btn>
            <v-spacer/>
            <v-btn
                :dark="!valid"
                :disabled="valid"
                @click="closeDialog()"
            >
                {{ $t("articleList.modifier") }}
            </v-btn>
            <v-spacer />
        </v-footer>
    </div>
</template>

<script>
import moment from 'moment'
import recapListItem from '../components/recapListItem.vue'
import localforage from 'localforage'
import signatureDialog from '../components/signatureDialog.vue'
import Vue from 'vue'

export default {
    name: 'recapList',
    components: {
        recapListItem,
        signatureDialog
    },
    props: {
        articles: {
            type: Array,
            default () {
                return []
            }
        },
        orders: {
            type: Array,
            default () {
                return []
            }
        },
        selectedClient: {
            type: Object,
            default () {
                return {}
            }
        },
        searchArticle: {
            type: String,
            default () {
                return ''
            }
        },
        valid: {
            type: Boolean,
            default () {
                return false
            }
        },
        isEdit: {
            type: Boolean,
            default () {
                return false
            }
        },
        signatureValidate: {
            type: Boolean,
            default () {
                return false
            }
        },
        showPrices: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    data () {
        return {
            date: '',
            notification: false,
            settings: {},
            signature: '',
            selectedPayment: '',
            remoteSettings: [],
            payments: [],
            paymentsEnabled: false
        }
    },
    computed: {
        filterOrder () {
            return this.orders.filter((item) => {
                return item.quantity > 0 || item.quantity < 0 || item.comment
            })
        },
        mappedOrders () {
            const mappedOrders = []
            this.filterOrder.forEach(order => {
                const article = this.articleMap.get(order.article)
                order.item = article
                mappedOrders.push(order)
            })
            return mappedOrders
        },
        articleMap () {
            const articleMap = new Map()

            this.articles.forEach(function (item) {
                articleMap.set(item.article, item)
            })

            return articleMap
        },
        filteredArticles () {
            if (!this.searchArticle) {
                return this.mappedOrders
            } else {
                return this.mappedOrders.filter((article) => {
                    return article.item.fullName.toLowerCase().includes(this.searchArticle.toLowerCase()) ||
                    article.item.article.toLowerCase().includes(this.searchArticle.toLowerCase()) ||
                    article.item.fullDesignation.toLowerCase().includes(this.searchArticle.toLowerCase())
                })
            }
        },
        scrollerItems () {
            const scrollerItems = []
            let currentHeader = null
            this.filteredArticles.forEach(article => {
                if (article.item) {
                    if (currentHeader !== article.item.fullDesignation) {
                        scrollerItems.push({
                            type: 'GroupHeader',
                            id: 'GroupHeader:' + article.item.article,
                            text: article.item.fullDesignation
                        })
                        currentHeader = article.item.fullDesignation
                    }
                    const item = article.item
                    scrollerItems.push({
                        type: 'ArticleListItem',
                        id: 'ArticleListItem:' + article.item.article,
                        item
                    })
                }
            })
            return scrollerItems
        },
        allTotal () {
            return this.orders.reduce((allTotal, order) => {
                const article = this.articleMap.get(order.article)
                let unitPrice = 0
                if (article && article.unitPrice) {
                    unitPrice = article.unitPrice
                }
                order.quantity.forEach((quantity) => {
                    allTotal = +allTotal + ((+quantity * +order.package.unitQuantity) * unitPrice)
                })
                return allTotal
            }, 0)
        },
        allQuantity () {
            return this.orders.reduce((allQuantity, order) => {
                order.quantity.forEach((quantity) => {
                    allQuantity = +allQuantity + (+quantity * +order.package.unitQuantity)
                })
                return allQuantity
            }, 0)
        },
        singleOrMultiple () {
            if (this.allQuantity > 1) {
                return 'Items'
            }
            return 'Item'
        }
    },
    created () {
        this.date = moment().format('YYYY-MM-DD HH:mm:ss')
        this.loadFromLocalStorage()
        this.loadRemoteSettings()
    },
    methods: {
        formatPrice (value) {
            const val = value.toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        },
        save () {
            this.$emit('save', {
                signature: this.signature,
                selectedPayment: this.selectedPayment
            })
        },
        loadRemoteSettings () {
            localforage.getItem('remoteSettings', (err, value) => {
                if (err !== null) {
                    this.remoteSettings = []
                    // eslint-disable-next-line
                    console.log(err)
                } else {
                    this.remoteSettings = value
                    for (const prop in value) {
                        value[value[prop].name] = value[prop].value
                        delete value[prop]
                    }
                }
                if (this.remoteSettings.ORDER_PROPOSAL_PAYMENT_METHODS_ACTIVE && this.remoteSettings.ORDER_PROPOSAL_PAYMENT_METHODS) {
                    this.paymentsEnabled = this.remoteSettings.ORDER_PROPOSAL_PAYMENT_METHODS_ACTIVE
                    this.payments = this.remoteSettings.ORDER_PROPOSAL_PAYMENT_METHODS
                }
            })
        },
        openSignatureDialog () {
            const signatureDialog = this.$refs.signatureDialog
                .open()
            return signatureDialog
        },
        getOrder (item) {
            const order = this.orders.find(order => order.article === item.article)
            return order
        },
        onUpdateCount (item, { index, count }) {
            Vue.set(item.quantity, index, count)
        },
        onUpdateCountSingle (item, { count, optional }) {
            Vue.set(item.quantity, 0, count)
            if (optional) {
                item.optional = optional
            }
        },
        onUpdateComment (item, { comment }) {
            item.comment = comment
        },
        onUpdatePackage (item, { packageID }) {
            item.package = item.item.packages[packageID]
        },
        onSaveSignature ({ signature, selectedPayment }) {
            this.signature = signature
            this.selectedPayment = selectedPayment
            this.$emit('saveSignature', {
                valid: true
            })
        },
        closeDialog () {
            this.$emit('closeDialog')
        },
        loadFromLocalStorage () {
            // load settings from localStorage
            const settings = localStorage.getItem('settings')
            if (settings) {
                this.settings = {
                    ...this.settings,
                    ...JSON.parse(settings)
                }
            }
        }
    }
}
</script>
<style>
.article-list {
    flex-grow: 1;
    overflow: auto;
    padding: 0;
}
.centered-input input {
    text-align: center;
}
.scroller {
    height: 100%;
}
.articleListContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
}
.recapContainer {
    flex-shrink: 0;
    border-bottom: solid 1px rgba(0,0,0,0.12);
}
.articleListFooter {
    flex-shrink: 0;
    height: 60px;
    flex-basis: 60px;
    min-height: 60px;
}
.textSize20 {
    font-size: 20px;
}
</style>
