<template>
    <v-dialog
        v-model="show"
        fullscreen
        :transition="false"
        persistent

    >
        <v-layout
            class="editContainer"
            column
            fill-height
        >
            <header>
                <div class="gradient" />
                <v-app-bar
                    color="black"
                    dark
                >
                    <v-btn
                        icon
                        :disabled="valid && !isEdit"
                        @click="close('arrow')"
                    >
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <span
                        class="text-h6"
                        v-if="!searchBar"
                    >
                        Récap
                    </span>
                    <v-text-field
                        v-if="searchBar"
                        :value="searchArticle"
                        @input="onInput"
                        clearable
                        placeholder="Search for Articles"
                        class="searchBar"
                        ref="search"
                    />
                    <v-spacer />
                    <v-btn
                        icon
                        @click="openSearch"
                    >
                        <v-icon>search</v-icon>
                    </v-btn>
                </v-app-bar>
            </header>
            <recapList
                class="orderList"
                :articles="articles"
                :orders="orders"
                :searchArticle="searchArticle"
                :selectedClient="selectedClient"
                :showButton="showButton"
                :showPrices="showPrices"
                :valid="valid"
                :isEdit="isEdit"
                :signatureValidate="signatureValidate"
                @saveSignature="onSaveSignature($event)"
                @closeDialog="close('modify')"
                @save="save($event)"
            />
        </v-layout>
    </v-dialog>
</template>

<script>
import recapList from '../components/recapList'
import _ from 'lodash'

export default {
    name: 'RecapDialog',
    components: {
        recapList
    },
    props: {
        articles: {
            type: Array,
            default () {
                return []
            }
        },
        orders: {
            type: Array,
            default () {
                return []
            }
        },
        selectedClient: {
            type: Object,
            default () {
                return {}
            }
        },
        valid: {
            type: Boolean,
            default () {
                return false
            }
        },
        isEdit: {
            type: Boolean,
            default () {
                return false
            }
        },
        signatureValidate: {
            type: Boolean,
            default () {
                return false
            }
        },
        showPrices: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    data () {
        return {
            show: false,
            resolve: null,
            reject: null,
            searchBar: false,
            searchArticle: '',
            showButton: true
        }
    },
    methods: {
        open () {
            this.show = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        close (value) {
            if ((this.valid && this.isEdit && this.signatureValidate) || (this.isEdit && value === 'arrow')) {
                window.location = '#/'
            }
            this.show = false
        },
        save ({ signature, selectedPayment }) {
            this.$emit('save', {
                valid: this.valid,
                signature: signature,
                selectedPayment: selectedPayment
            })
        },
        openSearch () {
            this.searchBar = true
            this.$nextTick(() => this.$refs.search.focus())
        },
        onSaveSignature ({ valid }) {
            this.$emit('validate', {
                valid: valid
            })
        },
        onInput: _.debounce(function (searchArticle) {
            this.searchArticle = searchArticle
        }, 300)
    }
}
</script>
<style>
    .editContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    }
    .editContainer header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    }
    .editContainer .orderList {
    overflow: auto;
    flex-grow: 1;
    }
</style>x-grow: 1;
